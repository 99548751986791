import React from 'react';
import Modal from 'react-bootstrap/Modal';

function GenericModal({
  title, body, show, onHide,
}: any) {
  return (
    <Modal size="lg" centered show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{body}</p>
      </Modal.Body>
    </Modal>
  );
}

export default GenericModal;
