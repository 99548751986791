import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import Card from 'react-bootstrap/Card';
import './FactorySubscription.css';
import { Button, Modal } from 'react-bootstrap';
import { httpsCallable } from 'firebase/functions';
import { onAuthStateChanged } from 'firebase/auth';
import { Elements } from '@stripe/react-stripe-js';
import { useSearchParams } from 'react-router-dom';
import { auth, functions } from '../configs/firebase-config';
import camiseta from '../assets/images/factory/camiseta.jpg';
import cinturon from '../assets/images/factory/cinturon.jpg';
import factoryLogo from '../assets/images/factory/factory-logo.png';
import iconoCirculoCuadrado from '../assets/images/factory/icono-circulo-cuadrado.png';
import iconoLapizRegla from '../assets/images/factory/icono-lapiz-regla.png';
import iconoPapelLapiz from '../assets/images/factory/icono-papel-lapiz.png';
import noDaysOff from '../assets/images/factory/no-days-off.jpeg';
import LoginComponent from '../components/LoginComponent';
import StripeSubscriptionModal from '../components/StripeSubscriptionModal';
import { stripePromise } from '../configs/stripe-config';
import PBOLoadingImage from '../components/PBOLoadingImage';
import GenericModal from '../components/GenericModal';
import StripeInfoModal from '../components/StripeInfoModal';

function FactorySubscription() {
  const [plazasBasic, setPlazasBasic] = useState(3);
  const [plazasFactory, setPlazasFactory] = useState(3);
  const [plazasPremium, setPlazasPremium] = useState(3);
  const [buyerId, setBuyerId] = useState<string>();
  const [showLoginModal, setShowLoginModal] = useState(false);
  // const [subscriptionId, setSubscriptionId] = useState<string>();
  const [stripeOptions, setStripeOptions] = useState({});
  const [stripeLoading, setStripeLoading] = useState(false);
  const [modalSubscriptionError, setModalSubscriptionError] = useState<string>();
  const [modalSubscriptionErrorShow, setModalSubscriptionErrorShow] = useState(false);
  const [showStripeInfoModal, setShowStripeInfoModal] = useState(false);
  const [searchParams] = useSearchParams();
  // const [showStripePaymentModal, setShowStripePaymentModal] = useState(false);

  const closeLoginModal = () => setShowLoginModal(false);

  async function createSubscription(priceId: string) {
    setStripeLoading(true);
    try {
      const cSub = httpsCallable<any, any>(functions, 'stripeFunctions-createSubscription');
      const subResult = await cSub({
        priceId,
        userId: buyerId,
      });
      const obtainedClientSecret = subResult.data.clientSecret;

      // setSubscriptionId(subResult.data.subscriptionId);
      const options = {
        clientSecret: obtainedClientSecret,
      };
      setStripeOptions(options);
    } catch (error: any) {
      setStripeLoading(false);
      setModalSubscriptionErrorShow(true);
      if (error.message === 'Reached limit of subscriptions') {
        setModalSubscriptionError('Se ha llegado al limite de subscripciones para este producto.');
      } else {
        setModalSubscriptionError('Ha ocurrido un error. Por favor, inténtalo de nuevo.');
      }
    } finally {
      setStripeLoading(false);
    }
  }

  function loadingOrShowModal() {
    if (stripeLoading) {
      return (
        <Modal size="lg" centered show={stripeLoading}>
          <PBOLoadingImage height={200} />
        </Modal>
      );
    }
    if (stripeOptions
      && Object.keys(stripeOptions).length !== 0
      && Object.getPrototypeOf(stripeOptions) === Object.prototype) {
      return (
        <Elements stripe={stripePromise} options={stripeOptions}>
          <StripeSubscriptionModal />
        </Elements>
      );
    }
    return null;
  }

  function showStripeMessages() {
    const intentClientSecret = searchParams.get('payment_intent_client_secret');
    if (showStripeInfoModal && intentClientSecret !== null) {
      return (
        <Elements stripe={stripePromise} options={{ clientSecret: intentClientSecret }}>
          {/* <StripeInfoModal data={{ documentaryId: params.documentaryId }} />  // TODO: Revisar parametros */}
          <StripeInfoModal />
        </Elements>
      );
    }
    return null;
  }

  useEffect(() => {
    const isIntentSecret = !!searchParams.get('payment_intent_client_secret');
    setShowStripeInfoModal(isIntentSecret);
    onAuthStateChanged(auth, (user) => {
      // setLoggedUser(user);
      if (user !== null) {
        setBuyerId(user.uid);
      }
    });
    setPlazasBasic(3);
    setPlazasFactory(3);
    setPlazasPremium(3);
  }, [buyerId]);

  return (
    <>
      <Navbar bg="light" expand="lg" className="mb-5">
        <Container>
          <Navbar.Brand href="#">
            <Image src={factoryLogo} alt="factoryLogo" className="factoryNavbarLogo" />
          </Navbar.Brand>
          <Navbar.Toggle
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          />
          <Navbar.Collapse id="navbarSupportedContent">
            <Nav className="me-auto mb-2 mb-lg-0">
              <Nav.Link aria-current="page" href="#">Home</Nav.Link>
              <Nav.Link href="https://factory.powerbuildingoficial.com/signin">Plataforma</Nav.Link>
              <Nav.Link href="#tarifas">Tarifas</Nav.Link>
              <Nav.Link href="#contacto">Contacto</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Container>

        <Row>
          <Col sm={6}>
            <h1 className="fw-bold">Ofrece tus propios diseños bajo demanda</h1>
            <p className="text-muted mt-5">
              Lanza tu marca de ropa o accesorios de entrenamiento sin tener que comprar stock y
              sin
              preocuparte de la
              logística.
            </p>
            <p className="text-center text-sm-start mb-0">
              <a href="https://factory.powerbuildingoficial.com/signin" className="btn btn-pbo pbo-rounded-left mt-5 mb-5" role="button">Probar plataforma gratis</a>
            </p>
          </Col>
          <Col sm={6}>
            <Row>
              <Col xs={6} className="d-flex align-items-center">
                <div className="me-2 mb-3">
                  <Image src={cinturon} alt="cinturon" fluid className="pbo-title-img-1" />
                </div>
              </Col>
              <Col xs={6}>
                <div className="me-2">
                  <Image src={camiseta} alt="camiseta" fluid className="pbo-title-img-2" />
                  <Image src={noDaysOff} alt="noDaysOff" fluid className="pbo-title-img-3" />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <span>Servicios</span>
          <h2 className="mb-5 fw-bold">¿Qué hacemos por ti?</h2>
          <Col sm={4} className="mb-4">
            <Row>
              <Col xs={4} sm={12} className="d-flex justify-content-center align-items-center mb-2">
                <div className="pbo-icons">
                  <Image src={iconoLapizRegla} alt="iconoLapizRegla" fluid />
                </div>
              </Col>
              <Col xs={8} sm={12}>
                <h4 className="text-sm-center">Producción</h4>
                <p className="text-muted text-sm-center">
                  Nos encargamos de imprimir tus diseños bajo demanda en todo tipo de prendas y accesorios.
                </p>
                <p className="text-sm-center">
                  <a href="#tarifas" className="pbo-link">Ver tarifas</a>
                </p>
              </Col>
            </Row>
          </Col>
          <Col sm={4} className="mb-4">
            <Row>
              <Col xs={4} sm={12} className="d-flex justify-content-center align-items-center mb-2">
                <div className="pbo-icons">
                  <Image src={iconoPapelLapiz} alt="iconoPapelLapiz" fluid />
                </div>
              </Col>
              <Col xs={8} sm={12}>
                <h4 className="text-sm-center">Logística</h4>
                <p className="text-muted text-sm-center">
                  No te preocupes por almacenar stock o gestionar los pedidos, nuestro equipo lo hace por tí.
                </p>
                <p className="text-sm-center">
                  <a href="#tarifas" className="pbo-link">Ver tarifas</a>
                </p>
              </Col>
            </Row>
          </Col>
          <Col sm={4} className="mb-4">
            <Row>
              <Col xs={4} sm={12} className="d-flex justify-content-center align-items-center mb-2">
                <div className="pbo-icons">
                  <Image src={iconoCirculoCuadrado} alt="iconoCirculoCuadrado" fluid />
                </div>
              </Col>
              <Col xs={8} sm={12}>
                <h4 className="text-sm-center">Web de venta</h4>
                <p className="text-muted text-sm-center">
                  Puedes integrar nuestro catálogo en tu sitio o diseñar tu propia tienda gratis con nosotros.
                </p>
                <p className="text-sm-center">
                  <a href="#tarifas" className="pbo-link">Ver tarifas</a>
                </p>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="mb-5" id="tarifas">
          <span className="mb-2">Tarifas</span>
          <Col md={4} className="mb-4">
            <Card className="pbo-gray-card h-100">
              <Card.Body>
                <Card.Title className="fw-bold">39,90€ al MES</Card.Title>
                <Card.Text className="mb-2 text-muted">Tarifa básica</Card.Text>
                <Card.Text className="mb-2 text-muted">
                  {`QUEDAN ${plazasBasic} PLAZAS`}
                </Card.Text>
                <hr />
                <ul>
                  <li>Personalización completa desde una sola unidad</li>
                  <li>Acceso a todo el catálogo</li>
                  <li>Logística y stock incluíudo</li>
                  <li>Tu propia web personalizada</li>
                  <li>Panel de ingresos</li>
                  <li>Plazas limitadas</li>
                </ul>
                {buyerId
                  ? <Button variant="link" className="pbo-link" onClick={() => createSubscription('price_1N4U01Fdvu7rT4J6uKw1P9yX')}>Contratar tarifa</Button>
                  : <Button variant="link" className="pbo-link" onClick={() => setShowLoginModal(true)}>Contratar tarifa</Button>}
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="mb-4">
            <Card className="pbo-dark-card h-100 me-2">
              <Card.Body>
                <Card.Title className="fw-bold">199,90€ al AÑO</Card.Title>
                <Card.Subtitle className="mb-2">Tarifa factory</Card.Subtitle>
                <Card.Text className="mb-2">
                  {`QUEDAN ${plazasFactory} PLAZAS`}
                </Card.Text>
                <hr />
                <ul>
                  <li>Promoción en PBO</li>
                  <li>Una muestra grátis</li>
                  <li>Prioridad de stock</li>
                  <li>Tarifa básica incluida</li>
                  <li>Plazas limitadas</li>
                </ul>
                {buyerId
                  ? <Button variant="link" className="pbo-link" onClick={() => createSubscription('price_1NNbcMFdvu7rT4J6gvluAo5V')}>Contratar tarifa</Button>
                  : <Button variant="link" className="pbo-link" onClick={() => setShowLoginModal(true)}>Contratar tarifa</Button>}
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="mb-sm-4">
            <Card className="pbo-gray-card h-100">
              <Card.Body>
                <Card.Title className="fw-bold">399,90€ al AÑO</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">Tarifa premium</Card.Subtitle>
                <Card.Text className="mb-2 text-muted">
                  {`QUEDAN ${plazasPremium} PLAZAS`}
                </Card.Text>
                <hr />
                <ul>
                  <li>Máxima promoción</li>
                  <li>Prioridad de stock y atención al cliente</li>
                  <li>Cuatro muestras grátis</li>
                  <li>Packagin PREMIUM en los 100 primeros pedidos</li>
                  <li>Tarifa básica incluida</li>
                  <li>Plazas limitadas</li>
                </ul>
                {buyerId
                  ? <Button variant="link" className="pbo-link" onClick={() => createSubscription('price_1N4TwEFdvu7rT4J6siqhg9Ui')}>Contratar tarifa</Button>
                  : <Button variant="link" className="pbo-link" onClick={() => setShowLoginModal(true)}>Contratar tarifa</Button>}
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="row mt-5 justify-content-center" id="contacto">
          <Col md={10}>
            <Card className="pbo-dark-card text-center me-2">
              <Card.Body>
                <Card.Title>¿Necesitas un presupuesto adaptado?</Card.Title>
                <Card.Text>
                  Si no estás interesado en ofrecer productos bajo demanda pero te gustaria pedir un presupuesto a medida
                  para hacer tu diseño escríbenos sin compromiso
                </Card.Text>
                <a href="mailto:soporte@powerbuildingoficial.com" className="btn btn-pbo">Contacto</a>
              </Card.Body>
            </Card>
          </Col>
        </Row>

      </Container>

      <footer className="mt-5 py-5 bg-light border-top">
        <Container>
          <Row>
            <Col sm={4}>
              <h5>Contacto</h5>
              <p>
                <a href="mailto:soporte@powerbuildingoficial.com" className="pbo-link">soporte@powerbuildingoficial.com</a>
              </p>
              <p>
                <a href="powerbuildingoficial.com">powerbuildingoficial.com</a>
              </p>
            </Col>
            <Col sm={4}>
              <h5>Sobre nosotros...</h5>
              <p className="text-muted">
                ¡Somos la RED SOCIAL del Fitness, somos PBO! ayudamos a influecers fitness a seguir
                creciendo y obtreniendo ingresos con todo tipo de facilidades.
              </p>
            </Col>
            <Col sm={4}>
              <h5>Terminos y condiciones</h5>
              <a href="https://www.powerbuildingoficial.com/pages/terminos-y-condiciones">Terminos y condiciones</a>
            </Col>
          </Row>
        </Container>
      </footer>

      {!buyerId
        && (
          <Modal size="lg" centered show={showLoginModal} onHide={closeLoginModal}>
            <Modal.Header closeButton>
              <Modal.Title>Log In</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <LoginComponent />
            </Modal.Body>
          </Modal>
        )}

      {showStripeMessages()}

      {loadingOrShowModal()}

      <GenericModal
        title="REWIND FITNESS HISPANO"
        body={modalSubscriptionError}
        show={modalSubscriptionErrorShow}
        onHide={() => setModalSubscriptionErrorShow(false)}
      />
    </>
  );
}

export default FactorySubscription;
